<template>
  <div>
    <common-loading :title="loading_tp.title" :message="loading_tp.message" :loading_dialog="loading_tp.dialog"
      :show_loading="loading_tp.show_loading" :show_confirm="loading_tp.show_confirm"
      v-on:confirm="loading_tp.dialog = false"></common-loading>
    <transcript-dialog :show.sync="show_tp"  :tpData="tp" :lbtype="lbtype"></transcript-dialog>
    <v-card class="card-shadow border-radius-xl">
      <div class="card-header-padding">
        <h5 class="font-weight-bold text-h5 text-typo mb-0">{{ $t("Land Transcript") }}</h5>
      </div>

      <v-card-text class="px-0 py-0">
        <v-data-table v-model="selected" :headers="headers" :options.sync="options" :items="items"
          :server-items-length="items_count" :loading="loading" hide-default-footer disable-pagination
          :single-select="false">
          <template v-slot:loading>
            {{ $t("Loading...") }}
          </template>
          <template v-slot:top>
            <v-row class="px-2">
              <v-col sm="1" cols="12" class="px-1 ml-2">
                <label class="text-md font-weight-bolder ms-1">縣市</label>
                <v-autocomplete :items="city_info" v-model="select_city" :class="common_input_class" outlined @change="get_districts"
                  single-line hide-details>
                </v-autocomplete>
              </v-col>
              <v-col sm="2" cols="12" class="px-1">
                <label class="text-md font-weight-bolder">行政區</label>
                <v-autocomplete :items="district_info" v-model="select_district" :class="common_input_class" outlined
                  single-line hide-details item-text="district_name" item-value="district_code" @change="get_regions">
                </v-autocomplete>
              </v-col>
              <v-col sm="2" cols="12" class="px-1">
                <label class="text-md font-weight-bolder">地段</label>
                <v-autocomplete :items="region_info" v-model="select_region" :class="common_input_class" outlined
                  single-line hide-details item-text="region_name" item-value="region_code">
                </v-autocomplete>
              </v-col>
              <!-- mno & sno -->
              <v-col sm="2" cols="12" class="px-1">
                <label class="text-md font-weight-bolder">母號</label>
                <v-text-field hide-details class="input-style font-size-input text-light-input placeholder-light mx-3"
                  outlined single-line height="36" v-model="mno" :placeholder="$t('transcript.mno')"
                  @keydown.enter="getTranscript">
                </v-text-field>
              </v-col>
              <v-col sm="2" cols="12" class="px-1">
                <label class="text-md font-weight-bolder">子號</label>
                <v-text-field hide-details class="input-style font-size-input text-light-input placeholder-light mx-3"
                  outlined single-line height="36" v-model="sno" :placeholder="$t('transcript.sno')"
                  @keydown.enter="getTranscript">
                </v-text-field>
              </v-col>
              <v-col sm="2" cols="12" class="px-0 d-flex align-end justify-end">
                <v-btn :ripple="false" elevation="0" color="#fff"
                  class="font-weight-bolder btn-default bg-gradient-default py-5 px-4 mx-2" small
                  @click="onOptionChange()">
                  <v-icon class="me-2" size="12">fa fa-search</v-icon>
                  {{ $t("Search") }}
                </v-btn>
                <v-btn :ripple="false" elevation="0" color="#fff"
                  class="font-weight-bolder btn-default bg-gradient-primary py-5 px-4 mx-2" small
                  @click="onReset()">
                  <v-icon class="me-2" size="12">fa fa-search</v-icon>
                  {{ $t("Reset") }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="6" class="ml-auto d-flex justify-end">
                <v-pagination prev-icon="fa fa-angle-left" next-icon="fa fa-angle-right" class="pagination"
                  color="#cb0c9f" v-model="page" :length="getPageCount()" circle :total-visible="15"></v-pagination>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.land_mark_data.buildings="{ item }">
            {{ getBuildingNumber(item) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon large class="mr-2" v-on="on" @click.stop="view(item)">mdi-briefcase-eye-outline</v-icon>
              </template>
              <span>{{ $t("View") }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="isAllowExportTranscript()">
              <template v-slot:activator="{ on }">
                <v-icon large class="mr-2" v-on="on" @click.stop="export_pdf(item.lbkey)">mdi-file-pdf-box</v-icon>
              </template>
              <span>{{ $t("Export") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col cols="12" lg="6" class="ml-auto d-flex justify-end">
            <v-pagination prev-icon="fa fa-angle-left" next-icon="fa fa-angle-right" class="pagination" color="#cb0c9f"
              v-model="page" :length="getPageCount()" circle :total-visible="15"></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-snackbar top v-model="snackbar.visible" :color="snackbar.color" class="snackbar-shadow" :timeout="timeout">
      <div class="d-flex align-start alert-notify">
        <v-icon size="24" class="text-white mr-5">ni ni-bell-55</v-icon>
        <p class="mb-0">
          <span class="font-size-root font-weight-600">Status</span>
          <br />
          {{ snackbar.message }}
        </p>
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn icon elevation="0" max-width="136" :ripple="false" height="43"
          class="font-weight-600 text-capitalize py-3 px-6 rounded-sm" color="rgba(255,255,255, .85)"
          @click="snackbar.visible = false" v-bind="attrs">
          <v-icon size="13">fas fa-times</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import TranscriptDialog from "./Widgets/TranscriptDialog.vue";
import TranscriptBase from "./Widgets/TranscriptBase.vue";
import CommonWidget from "./Widgets/CommonWidget.vue";
import CommonLoading from "@/views/Pages/General/Widgets/CommonLoading.vue";
import { customer_land_mission } from "@/definitions.js";

export default {
  name: "land-transcript-tables",
  data: (vm) => ({
    common_input_class: "font-size-input border border-radius-md",
    selected: [],
    edit_dialog: false,
    start_menu: false,
    start_date: null,
    end_menu: false,
    end_date: null,
    keyword: "",
    base_url: "/api/v1/transcripts/lands/",
    timeout: 2000,
    lbtype: "L",
    tp: {
      "mark": {},
      "o_data": [],
      "c_data": [],
    },
    customer_land_mission: customer_land_mission,
    select_plan: null,
    headers: [
      // lbkey
      // {
      //   text: vm.$i18n.t("lbkey"),
      //   cellClass: "border-bottom",
      //   sortable: false,
      //   value: "lbkey",
      //   class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      // },
      {
        text: vm.$i18n.t("district"),
        align: "right",
        cellClass: "border-bottom",
        sortable: false,
        value: "land_mark_data.district",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },
      {
        text: vm.$i18n.t("region"),
        cellClass: "border-bottom",
        sortable: false,
        value: "land_mark_data.region",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },
      {
        text: vm.$i18n.t("land-nos"),
        cellClass: "border-bottom",
        sortable: false,
        value: "land_mark_data.nos",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },
      {
        text: vm.$i18n.t("area"),
        cellClass: "border-bottom",
        sortable: false,
        value: "land_mark_data.area",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },
      {
        text: vm.$i18n.t("zone"),
        cellClass: "border-bottom",
        sortable: false,
        value: "land_mark_data.zone",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },
      {
        text: vm.$i18n.t("buildings_num"),
        cellClass: "border-bottom",
        sortable: false,
        value: "land_mark_data.buildings",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },
      {
        text: vm.$i18n.t("owners_num"),
        cellClass: "border-bottom",
        sortable: false,
        value: "o_num",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },

      {
        text: vm.$i18n.t("creditors_num"),
        cellClass: "border-bottom",
        sortable: false,
        value: "c_num",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },
      {
        text: vm.$i18n.t("actions"),
        cellClass: "border-bottom",
        align: "end",
        value: "actions",
        sortable: false,
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },
    ],
  }),
  mixins: [TranscriptBase, CommonWidget],
  components: {
    TranscriptDialog,
    CommonLoading,
  },
  mounted() {
    this.get_districts();
    this.getTranscript();
  },
  methods: {
    ...mapGetters(["getToken", "isAllowExportTranscript"]),
    onReset() {
      console.log("on Reset");
      this.page = 1;
      this.start_date = null;
      this.end_date = null;
      this.keyword = "";
      this.select_region = "9999"
      this.mno = null;
      this.sno = null;
      this.select_city = "H"
      this.select_district = '01';
      this.get_districts();
      this.getTranscript();
    },
    onOptionChange() {
      this.page = 1;
      this.getTranscript();
    },
    onAddCustomer() {
      this.isCreate = true;
      this.editedIndex = -1;
      this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
      this.edit_dialog = true;
    },
    edit(item) {
      this.isCreate = false;
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.originItem = JSON.parse(JSON.stringify(item));
      this.edit_dialog = true;
    },
    close() {
      this.onClear();
      this.edit_dialog = false;
    },
    onActionDone(msg) {
      this.close();
      this.getTranscript();
      this.showSuccessAlert(msg); //"Add Customer");
    },
    showSuccessAlert(message) {
      console.debug(message);
    },
    getNumber(data) {
      if (data != null && data.length > 0) {
        const owners = data.split(",");
        return owners.length;
      }
      return 0;
    },
    getPrivate(data) {
      if (data != null && data.length > 0) {
        let owners = data.split(",");
        if (owners.length > 2) {
          let display_owners = owners.slice(0, 2);
          return `${display_owners.join(",")}... (${owners.length})`;
        }
        return `${owners.join(",")} (${owners.length})`;
      }
      return 0;
    },
    view(item) {
      console.log("show lbkey ", item.lbkey);
      this.get_transcript_by_key(item.lbkey);
    },
    setViewTranscript(item) {
      this.tp = item;
      this.show_tp = true;
    },
    getBuildingNumber(item) {
      if (item == null || item.land_mark_data == null || item.land_mark_data.buildings == null) return 0;
      let buildings = item.land_mark_data.buildings;
      return JSON.parse(buildings).length;
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
};
</script>
<style scoped>
/* @import "../../../assets/css/jub.css"; */

.download_txt:hover {
  color: blue !important;
  text-decoration: underline;
}
</style>
